<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end"></div>
            <div class="row py-3">
              <div class="col-md-4 col-12 d-flex align-items-center">
                <div class="symbol symbol-60 symbol-lg-70 details-symbol">
                  <div
                    class="symbol-label symbol-label-profile"
                    :style="
                      companyLogo
                        ? 'background-image: url(' +
                          companyLogo +
                          '); background-color: #ffffff;'
                        : ''
                    "
                  ></div>
                </div>
              </div>
              <div class="col-md-8 col-12 row align-items-center">
                <div class="col-12">
                  <div class="font-weight-bolder font-size-h5 text-dark-75">
                    <b-skeleton v-if="!client.id" type="text" height="1.5rem" />
                    <span>{{ client.name }}</span>
                  </div>
                  <div class="text-muted">
                    <b-skeleton v-if="!client.id" type="text" width="60%" />
                    <span>{{ client.number }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: isExactActive }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 client-details">
        <Information
          v-if="$route.name === 'tenantData'"
          :client="client"
          :is-busy-parent="isBusy"
        />
        <Contact
          v-else-if="$route.name === 'tenantDataContact'"
          :client="client"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Information from "@/components/Tenants/Account/Components/Information";
import Clients from "@/components/Admins/Clients/clients";
import Contact from "@/components/Tenants/Account/Components/Contact";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Tenant from "@/components/Tenants/tenant";
import Media from "@/components/Admins/Media/media";
import { bus } from "@/main";
import { mapGetters } from "vuex";
import { UPDATE_SELECTED_CLIENT } from "@/core/services/store/switchArea.module";
import store from "@/core/services/store";

export default {
  components: {
    Information,
    Contact
  },
  data() {
    return {
      isBusy: 0,
      client: {
        additional_data: {
          contact: {}
        },
        contactPerson: {},
        language: {}
      },
      companyLogo: "",
      tabIndex: 0,
      tabNavigation: [
        {
          route: "tenantData",
          label: "client.informationClient",
          icon: "fal fa-user"
        },
        {
          route: "tenantDataContact",
          label: "client.contactData",
          icon: "fal fa-paper-plane"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["selectedClient"])
  },
  watch: {
    selectedClient: function () {
      this.loadClient();
    }
  },
  mounted() {
    this.updateStore(this.selectedClient.id);

    this.loadClient();

    bus.$on("clientUpdated", this.loadClient);

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.tenantData"),
        route: ""
      }
    ]);
  },
  beforeDestroy() {
    bus.$off("clientUpdated", this.loadClient);
  },
  methods: {
    async loadClient() {
      this.isBusy++;
      this.companyLogo = "";
      let data = this.selectedClient;

      this.validateProps(data);
      this.isBusy--;
      if (this.client.logo_id !== undefined && this.client.logo_id !== null) {
        this.isBusy++;
        Media.get(this.client.logo_id)
          .then(mediaResponse => {
            this.companyLogo = mediaResponse.data.data.link;
            this.isBusy--;
          })
          .catch(error => {
            this.$swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy--;
          });
      }
      if (this.client.main_contact_id) {
        this.isBusy++;
        Tenant.getMainContact(this.client.main_contact_id)
          .then(adminResponse => {
            this.client.contactPerson = adminResponse.data.data;
            this.isBusy--;
          })
          .catch(() => {
            this.isBusy--;
          });
      }
    },
    validateProps(client) {
      if (!client) client = {};
      if (!client.additional_data) client.additional_data = { contact: {} };
      if (!client.additional_data.contact) client.additional_data.contact = {};
      if (!client.contactPerson) client.contactPerson = {};
      if (!client.language) client.language = {};
      this.client = client;
    },
    updateStore(clientId) {
      Clients.get(clientId)
        .then(response => {
          store.dispatch(UPDATE_SELECTED_CLIENT, response.data.data);
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    }
  }
};
</script>
